import React, { useState, useEffect } from 'react';
import { useAuth0, withAuthenticationRequired } from '@auth0/auth0-react';
import Loading from './Loading';
import axios from 'axios'
import './Modal.css'
import { useNavigate } from 'react-router-dom';
import logo from '../assets/e1-logo-large.png';


export const DeleteUser = () => {
    const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
    const navigate = useNavigate();
    const [confirmation, setConfirmation] = useState('');
    const [userId, setUserId] = useState(null);
    const [nonDeletable, setNonDeletable] = useState(false);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchIdToken = async () => {
            const idToken = await getIdTokenClaims();
            const userId = idToken.sub;
            setUserId(userId);
            //check if sub contains the words auth0 or waad
            if (userId.includes('auth0') || userId.includes('waad')) {
                // Redirect to the Error page with query parameters
                setNonDeletable(true);
            }
        };
        fetchIdToken();
    }, [getIdTokenClaims]);

    const deleteUser = async () => {
        setLoading(true);
        // Call the Auth0 Management API to delete the user
        const accessToken = await getAccessTokenSilently({
            prompt: "none"
        });
        

        // Set the headers
        const headers = {
            'Content-Type': 'application/json',
            'Authorization': accessToken
        };

        // Make the DELETE request
        try {
            const response = await axios.delete('https://e1-prod-prod-apim-apimservice.azure-api.net/user', { headers });
            if (response.status === 204) {
                // Redirect to the Delete Confirmation page with query parameters
                navigate('/delete-confirmation?userDeleted=true');
            } else if (response.status === 500) {
                // Redirect to the Error page with query parameters
                navigate(`/error?error=${response.status}&error_description=Server Error`);
            }
        } catch (error) {
            console.error(error);
            // Check if error.response exists before trying to access error.response.status
            if (error.response) {
                // Redirect to the Error page with query parameters
                navigate(`/error?error=${error.response.status}&error_description=${encodeURIComponent(error.message)}`);
            } else {
                // Redirect to the Error page with a generic error message
                navigate(`/error?error=Network Error&error_description=${encodeURIComponent(error.message)}`);
            }
        
            setLoading(false);
        }

    };

    return (
        loading ? <Loading /> :
        <div className='overlay'>
            <div className='modal-box'>
                <div style={{ padding: 20 }} />
                <div className='modal-content'>
                    <img alt="logo" src={logo} className='logo-ee' />
                    {nonDeletable ? (
                        <>
                            <h4 className='modal-title'>Account Deletion</h4>
                            <p className='text' style={{ textAlign: 'center' }}>EE Employees and Business Partners are not able to delete their account through this page.</p>
                            <p className='text' style={{ textAlign: 'center' }}>Please contact your Education Esbjerg representative to request an account deletion.</p>
                        </>
                    ) : (
                        <>
                            <h4 className='modal-title'>Confirm Account Deletion</h4>
                            <p className='text'>You are about to <u>permanently delete</u> your account.</p>
                            <p className='text'>If you wish to keep your account, navigate away from this page.</p>
                            <p className='text'>If you are sure, please enter "Delete" in the text field below and click 'Confirm Deletion'.</p>
                            <input type="text" className="delete-field" placeholder='Type "Delete"' value={confirmation} onChange={e => setConfirmation(e.target.value)} />
                                 <button type='button' className='btn-blue' onClick={deleteUser} disabled={confirmation !== 'Delete'} style={{ opacity: confirmation === 'Delete' ? 1 : 0.5 }}>Confirm Deletion</button>
                        </>
                    )}
                </div>
            </div>
        </div>
        
    );
}

export default withAuthenticationRequired(DeleteUser, {
    onRedirecting: () => <Loading />,
});