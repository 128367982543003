import React, { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import Loading from './Loading';

export default function RedirectToSSO() {
    const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (isLoading) {
            return;
        }

        if (isAuthenticated) {
            window.location.href = "https://login.e1education.dk/samlp/VvufcmSr5ZTtFXJO5LPuCSzkLbfhkRjV";
        } else if (!isAuthenticated) {
            loginWithRedirect();
        }
    }, [isLoading, isAuthenticated, loginWithRedirect]);

    if (isLoading) {
        return <Loading />;
    }

    return null;
}