import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import axios from 'axios';
import './Consent.css';
import logo from '../assets/e1-logo.png';


const Consent = () => {
    const { user, isAuthenticated, getIdTokenClaims, getAccessTokenSilently, loginWithPopup } = useAuth0();
    const [consent, setConsent] = useState(null);

    useEffect(() => {
        const checkConsent = async () => {
            if (isAuthenticated) {
                const res = await getIdTokenClaims();
                if (res['https://login.e1education.dk/consent']) {
                    setConsent(res['https://login.e1education.dk/consent']);
                } else {
                    setConsent(false);
                }
            }
        };

        checkConsent();
    }, [isAuthenticated, getIdTokenClaims]);

    const getManagementApiToken = async () => {
        try {
            const token = await getAccessTokenSilently({
                audience: 'https://login.e1education.dk/api/v2/',
                scope: 'openid profile email read:current_user update:current_user_metadata create:current_user_metadata',
                ignoreCache: true
            });

            return token;
        } catch (error) {
            console.error('Error getting Management API token:', error);
        }
    };

    const updateUserMetadata = async (userId, consent) => {
        const accessToken = await getManagementApiToken();
        const url = `https://login.e1education.dk/api/v2/users/${userId}`;
        const headers = {
            'Authorization': `Bearer ${accessToken}`,
            'Content-Type': 'application/json'
        };
        const data = {
            user_metadata: {
                consent: consent
            }
        };

        try {
            const response = await axios.patch(url, data, { headers: headers });
        } catch (error) {
            console.error('Error updating user metadata');
        }
    };

    const handleConsent = async () => {
        setConsent(true);
        await updateUserMetadata(user.sub, true);
        window.location.reload();
    };

    if (consent === false) {
        return (
            <div className="contentContainer">
                <div className="left">
                    <div className="content">
                        <img src={logo} className="logoEE" alt="Logo" />
                        <h1>Consent Required</h1>
                        <p>Hi {user.email},</p>
                        <p>Access to E.1 Education requires your consent to the following items.</p>
                        <div className="box">
                            <div aria-live="assertive" aria-atomic="true">
                                <ul>
                                    <li><span id="consentText"><b>WAYF:</b> Access to your profile and email.</span></li>
                                    <li><span id="consentText"><b>E.1 Education:</b> Please review your <a href="https://e1education.dk/consent-to-the-processing-and-sharing-of-your-personal-data/" target="_blank" id="consentLink">consent</a> for your signup.</span></li>
                                </ul>
                            </div>
                        </div>
                        <button onClick={handleConsent}>Accept</button>
                    </div>
                </div>
                <div className="right"></div>
            </div>
        );
    }

    return null;
};

export default Consent;