import React from 'react';
import { useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import './Modal.css';
import logo from '../assets/e1-logo-large.png';


const DeleteConfirmation = () => {
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    // Access query parameters
    const userDeleted = queryParams.get('userDeleted');

    // Only display the page if the query parameters exist
    if (userDeleted !== 'true') {
        loginWithRedirect();
        return null;
    }

    return (
        <div className='overlay'>
            <div className='modal-box'>
                <div style={{ padding: 20 }} />
                <div className='modal-content'>
                    <img alt="logo" src={logo} className='logo-ee' />
                    <h4 className='modal-title'>Account Deletion Confirmed</h4>
                    <p style={{ textAlign: 'center', paddingTop: "20px" }}>Your account has been <u>permanently deleted</u>.</p>
                    <p className='section-header'>Saved data</p>
                    <ul>
                        <li style={{paddingBottom: "10px" }}>You have been withdrawn from all event attendance lists.</li>
                        <li style={{ paddingBottom: "10px" }}>Any content you have bookmarked or information you have saved has been removed</li>
                        <li>No further information will be recorded.</li>
                    </ul>
                    <p className='section-header'>Account reactivation</p>
                    <p style={{ textAlign: 'center' }}>To reactivate your account, simply log back in at any time.</p>
                    <p style={{ textAlign: 'center', paddingTop: "20px" }}> If this was a mistake, please contact our support team.</p>
                </div>
            </div>
        </div>
    );
}

export default DeleteConfirmation;