import React, { useEffect, useState } from 'react';
//import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'; // import Routes and Route
import { useAuth0 } from '@auth0/auth0-react';
import DeleteUser from './components/DeleteUser';
import Loading from "./components/Loading";
import RedirectToSSO from "./components/RedirectToSSO";
import DeleteConfirmation from "./components/DeleteConfirmation";
import Error from "./components/Error";
import Consent from "./components/Consent";

// styles
import "./App.css";

// fontawesome
import initFontAwesome from "./utils/initFontAwesome";
initFontAwesome();


const ConsentWrapper = ({ children }) => {
  const { isAuthenticated, getIdTokenClaims } = useAuth0();
  const [consent, setConsent] = useState(null);
  const [checkCompleted, setCheckCompleted] = useState(false);

  useEffect(() => {
    if (isAuthenticated) {
      getIdTokenClaims().then((claims) => {
        setConsent(claims['https://login.e1education.dk/consent']);
        setCheckCompleted(true);
      });
    } else {
      setCheckCompleted(true);
    }
  }, [isAuthenticated, getIdTokenClaims]);

  if (!checkCompleted) {
    return null; // or return a loading component
  }

  if (consent === false) {
    return <Consent />;
  }

  return children;
};

function App() {
  const { isLoading, error } = useAuth0();

  if (error) {
    return <div>Oops... {error.message}</div>;
  }

  if (isLoading) {
    return <Loading />;
  }

  return (
    <div className="App">
      <Router>
        <ConsentWrapper>
          <Routes>
            <Route path="/delete" element={<DeleteUser />} />
            <Route path="/delete-confirmation" element={<DeleteConfirmation />} />
            <Route path="/error" element={<Error />} />
            <Route path="/*" element={<RedirectToSSO />} />
          </Routes>
        </ConsentWrapper>
      </Router>
    </div>
  );
}

export default App;