// Error.js
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { withAuthenticationRequired } from '@auth0/auth0-react';

function Error() {
    const [searchParams] = useSearchParams();
    const error = searchParams.get('error');
    const errorDescription = searchParams.get('error_description');

    return (
        <div className='overlay'>
            <div className='modal-box'>
                <div style={{ padding: 20 }} />
                <div className='modal-content'>
                    <img alt="logo" src="https://prod-aaudxp-cms-001-app.azurewebsites.net/media/4xzllwza/e1-mark-rgb-black.png?width=960" className='logo-ee' />
                    <h4 className='modal-title'>Oops! An Error occurred!</h4>
                    <p className='textError'>Unfortunately, something seems to have gone wrong. Please try again later.</p>
                    <p className='textError'>If the issue persists reach out to E.1 Support.</p>
                    {error && <p className='textError'>Error: {error}</p>}
                    {errorDescription && <p className='textError'>Description: {errorDescription}</p>}
                    
                    
                </div>
            </div>
        </div>
    );
}

export default Error;